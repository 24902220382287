/* src/fonts.css */
@font-face {
    font-family: 'Geist-Regular';
    src: url('./fonts/Geist-Regular.woff2') format('woff2'),
         url('./fonts/Geist-Regular.woff') format('woff'); 
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Geist-Medium';
    src:url('./fonts/Geist-Medium.woff2') format('woff2'),
         url('./fonts/Geist-Medium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Geist-Light';
    src:url('./fonts/Geist-Light.woff2') format('woff2'),
         url('./fonts/Geist-Light.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }