/* Ensure the body and main container take full height */
@import url('./fonts.css');
body {
  font-family: 'Geist-Regular', sans-serif;
  font-size: 16pt; 
  line-height: 1.2;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
  background-color: #1a1a1a;
 /* cursor: url('/images/cursor.png'),crosshair;*/

}

.MainPage {
  display: grid;
  background-color: #1a1a1a;
  color: white;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 1fr;
  grid-template-areas:
    "header"
    "main"
    "footer";
  height: 100vh;
  padding-top: 70px;
}

.MainPage-header {
  grid-area: header;
  background-color: #1a1a1a;
  color: #fff;
  padding: 2.5rem;
  /* margin: 0.5rem 0;*/
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 160px; 
  margin-top: 45px; 
}

.MainPage-logo {
  width: 50px; /* Adjust the size as needed */
  height: auto;
  margin-bottom: 20px;
  padding: 2.5rem;
  margin-left: -50px;
}

.nav ul {
  list-style: none;
  display: flex;
  margin-right: 145px;
}

.nav li {
  position: relative;
}

.nav a {
  color: #fff;
  text-decoration: none;
    display: block;
    font-family: 'Geist-Regular', sans-serif;
    font-size: 18pt; /* Adjust the font size as needed */
  }

.nav li:hover a {
  
  color: #fff; /* Change text color on hover */
  border-radius: 1px; /* Optional: Add rounded corners */
}

.nav li::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  background: #dcf92d;;
  left: 50%;
  bottom: 0;
  transition: width 0.3s ease, left 0.3s ease;
}

.nav li:hover::after {
  width: 100%;
  left: 0;
}


.footerp ul {
  list-style: none;
  display: flex;
  gap: 1rem;
  padding-right: 70px
}

.site-main {
  grid-area: main;
  padding: 1.5rem;
}

.content {
  text-align: left; /* Align content to the left */
  padding-left: 6px; /* Ensure consistent padding */
  margin-left:160px; 
}

.name {
  font-family: 'Geist-Regular', sans-serif;
  font-size: 18pt; /* Adjust the font size as needed */
  margin: 0; /* Remove default margin */
  color: #fff;;
}

.pagetitle {
  font-family: 'Geist-Medium', sans-serif;
  font-size: 78pt; /* Adjust the font size as needed */
  margin: 0; /* Remove default margin */
  color: #fff;
  margin-left: -8px; 
}

.porttitle{
  font-family: 'Geist-Medium', sans-serif;
  font-size: 16pt; /* Adjust the font size as needed */
  margin: 0; /* Remove default margin */
  text-align: left;
  color: #fff;
  margin-bottom:15px;
}

.portbeskrivelse p{
  font-family: 'Geist-Light', sans-serif;
  font-size: 12pt; /* Adjust the font size as needed */
  margin: 0; /* Remove default margin */
  text-align: left;
  color: #fff;
  padding-bottom: 3px; 
}

.portfolio {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: 145px;
  margin-right: 145px;
  margin-top:40px;
}

#portfolio-item-0{
  margin-top:150px;
  padding-right: 70px; 
  margin-left: 10px; 
}

.portfolio-item {
  flex: 1 1 300px; /* Flex-grow, flex-shrink, flex-basis */
  
  padding: 10px; 
}

.portfolio-item img {
  max-width: 100%;
  height: auto;
}

.portfolio-item-buttons {
  display: flex;
  justify-content:  center;
    margin-top: -100px;
    margin-bottom: 95px;
}
.portfolio-button {
  background-color: transparent; /* Make the button background transparent */
  color: #fff;
  border: 2px solid #dcf92d; /* Change border color to #dcf92d */
  padding: 10px 10px;
  cursor: pointer;
  border-radius: 15px; /* Make the buttons rounded */
  width: 200px; /* Set a fixed width for the buttons */
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Geist-Medium', sans-serif;
  font-size: 14pt; /* Adjust the font size as needed */
  margin-left:20px; 
}


.portfolio-button:hover {
  background-color: #dcf92d; 
  color: #1a1a1a; /* Change text color on hover */
 
}

.portfolio-item img {
  max-width: 100%; /* Ensure the image takes the full width of the container */
  height: auto; /* Maintain the aspect ratio of the image */
}
.site-footer {
  grid-area: footer;
  background-color: #1a1a1a;
  color: #fff;
  padding: 1rem;
  text-align: center;
  border-top: 0.5px solid #fff; /* Add a stroke to the top of the footer */
 /* display: grid;*/
  grid-template-rows: auto auto;
  gap: 0.5rem;
  margin-top: 45px; 
 
}

.footer-row {
  display: flex;
 
  justify-content: space-between;
  gap: 1rem;
 
}

.footerpmail {
  font-family: 'Geist-Regular', sans-serif;
  font-size: 14pt; /* Adjust the font size as needed */
 
  
  margin-left: 175px;  
  margin-top:30px; 

}


.footerp {
    font-family: 'Geist-Regular', sans-serif;
    font-size: 14pt; /* Adjust the font size as needed */
    margin: 0; /* Remove default margin */
    margin-top:3px; 
    margin-left: 175px;  
    margin-bottom: 30px; 
}



.footerpLinkedIn{margin-right: 160px; }

.footerpLinkedIn a {
  font-family: 'Geist-Regular', sans-serif;
  color: #fff;
  text-decoration: none;
  font-size: 14pt;
}
.footerp a {
  color: #fff;
  text-decoration: none;
}

.footerpmail a {
  color: #fff;
  text-decoration: none;
}

/* Extra small devices (phones, less than 576px) */
@media (max-width: 575.98px) {
  .portfolio {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
  }

  #portfolio-item-0{
    flex: 1 1 100%;
    margin-top:0;
    padding-right: 0; 
  }

  .portfolio-item {
    flex: 1 1 100%;
    margin-bottom: 30px; 
    padding:2px;
  }
  .portbeskrivelse{
    margin-top: -10px;
  }

  .portfolio-item img {
    max-width: 100%;
    height: auto;
    margin-left: 10px; 
  }
  #portfolio-item-0 img {
    max-width: 100%;
    height: auto;
    margin-left: -6px; 
  }

  #portfolio-item-0 .portfolio-item-buttons{
    margin-top: -70px;
    margin-bottom: 50px;
    margin-right: 35px;
  }

  #portfolio-item-1 .portfolio-item-buttons{
    margin-top: -70px;
    margin-bottom: 50px;
    margin-right: 25px;
  }

  .portfolio-button {
    width: 100px;
    font-size: 8pt;
    border-radius: 10px;
  }

  .content {
    margin-left: 0;
  }

  .name {
    font-size: 0.8em;
    margin-left: 0;
  }

  .pagetitle {
    font-size: 3.1em;
   margin-left: -5px; 
    margin-bottom: 50px;
  }

  .MainPage-header {
    margin-left: 8px;
    margin-top: 20px;
  }


  #portfolio-item-1 .porttitle {
    margin-left:10px; 
  }
  .porttitle {
    font-size: 10pt;
  }

  #portfolio-item-1 .portbeskrivelse p {
    margin-left:10px; 
  }
  .portbeskrivelse p {
    font-size: 6pt;
  }

  .footerpmail {
    font-size: 10pt;
    margin-left: 20px;
  }

  .footerp {
    font-size: 10pt;
    margin-left: 20px;
  }

  .footerpLinkedIn {
    margin-right: 10px;
  }

  .footerpLinkedIn a {
    font-size: 10pt;
  }

  .nav ul {
    margin-right: -10px;
  }

  .nav a {
    font-size: 10pt;
  }
}

/* Small devices (phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .portfolio {
    margin-left: 0;
    margin-right: 0;
        margin-top: 0px;
  }

  .portfolio-item {
    flex: 1 1 100%;
  }

  .portfolio-item img {
    max-width: 100%;
    height: auto;
  }

  .portfolio-button {
    width: 120px;
    font-size: 9pt;
  }

  .content {
    margin-left: 0;
  }

  .name {
    font-size: 1.2em;
    margin-left: 0;
  }

  .pagetitle {
    font-size: 2em;
    margin-left: 0;
  }

  .MainPage-header {
    margin-left: 8px;
    margin-top: 25px;
  }

  .porttitle {
    font-size: 12pt;
  }

  .portbeskrivelse p {
    font-size: 8pt;
  }

  .footerpmail {
    font-size: 12pt;
    margin-left: 20px;
  }

  .footerp {
    font-size: 12pt;
    margin-left: 20px;
  }

  .footerpLinkedIn {
    margin-right: 20px;
  }

  .footerpLinkedIn a {
    font-size: 12pt;
  }

  .nav ul {
    margin-right: -10px;
  }

  .nav a {
    font-size: 12pt;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .portfolio {
    margin-left: 20px;
    margin-right: 20px;

  }

  .portfolio-item {
    flex: 1 1 100%;
  }

  #portfolio-item-0 {
    margin-left:0; 
  }

  .portfolio-item img {
    max-width: 100%;
    height: auto;
  }

  .portfolio-button {
    width: 150px;
    font-size: 10pt;
  }

  .content {
    margin-left: 20px;
  }

  .name {
    font-size: 1.5em;
    margin-left: 0;
  }

  .pagetitle {
    font-size: 2.5em;
    margin-left: 0;
  }

  .MainPage-header {
    margin-left: 20px;
    margin-top: 30px;
  }

  .porttitle {
    font-size: 14pt;
  }

  .portbeskrivelse p {
    font-size: 10pt;
  }

  .footerpmail {
    font-size: 14pt;
    margin-left: 40px;
  }

  .footerp {
    font-size: 14pt;
    margin-left: 40px;
  }

  .footerpLinkedIn {
    margin-right: 40px;
  }

  .footerpLinkedIn a {
    font-size: 14pt;
  }

  .nav ul {
    margin-right: 0;
  }

  .nav a {
    font-size: 14pt;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .portfolio {
    margin-left: 40px;
    margin-right: 40px;
  }

  .portfolio-item {
    flex: 1 1 45%;
  }

  .portfolio-item img {
    max-width: 100%;
    height: auto;
  }

  .portfolio-button {
    width: 180px;
    font-size: 12pt;
  }

  .content {
    margin-left: 40px;
  }

  .name {
    font-size: 1.8em;
    margin-left: 0;
  }

  .pagetitle {
    font-size: 3em;
    margin-left: 0;
  }

  .MainPage-header {
    margin-left: 40px;
    margin-top: 35px;
  }

  .porttitle {
    font-size: 16pt;
  }

  .portbeskrivelse p {
    font-size: 12pt;
  }

  .footerpmail {
    font-size: 16pt;
    margin-left: 50px;
  }

  .footerp {
    font-size: 16pt;
    margin-left: 50px;
  }

  .footerpLinkedIn {
    margin-right: 50px;
  }

  .footerpLinkedIn a {
    font-size: 16pt;
  }

  .nav ul {
    margin-right: 20px;
  }

  .nav a {
    font-size: 16pt;
  }
}

/* Extra large devices (large desktops, 1200px and up) 
@media (min-width: 1200px) {
  .portfolio {
    margin-left: 60px;
    margin-right: 60px;
  }

  .portfolio-item {
    flex: 1 1 30%;
  }

  .portfolio-item img {
    max-width: 100%;
    height: auto;
  }

  .portfolio-button {
    width: 200px;
    font-size: 14pt;
  }

  .content {
    margin-left: 60px;
  }

  .name {
    font-size: 2em;
    margin-left: 0;
  }

  .pagetitle {
  
  
  }

  .MainPage-header {
    margin-left: 60px;
    margin-top: 40px;
  }

  .porttitle {
    font-size: 18pt;
  }

  .portbeskrivelse p {
    font-size: 14pt;
  }

  .footerpmail {
    font-size: 18pt;
    margin-left: 70px;
  }

  .footerp {
    font-size: 18pt;
    margin-left: 70px;
  }

  .footerpLinkedIn {
    margin-right: 70px;
  }

  .footerpLinkedIn a {
    font-size: 18pt;
  }

  .nav ul {
    margin-right: 40px;
  }

  .nav a {
    font-size: 18pt;
  }
}*/